<template>
  <div class="page" style="width: 100%; margin: 0 auto;">
    <div style="text-align:center;padding:6px 0;background-color: #1283FF;">
      <el-date-picker v-model="ckdMonth" value-format="yyyy-MM" type="month" placeholder="选择月" @change="reloadData">
      </el-date-picker>

    </div>
    <div style="height:4px"></div>
    <div class="tabbox">
      <div :class="['tabitem', tabIdx == 0 ? 'tabckd' : '']" @click="setCkd(0)">营收表</div>

      <div :class="['tabitem', tabIdx == 1 ? 'tabckd' : '']" @click="setCkd(1)">利润表</div>

    </div>
    <div style="height:4px"></div>
    <el-row v-show="tabIdx == 0">
      <div style="text-align: center; padding: 10px; color:#fff; background-color: #1283FF;">
        <div>{{ ckdMonth }}月营收报表</div>
        <div style="font-size:10px">向左滑动屏幕查看详细</div>
      </div>

      <el-table :data="DataList" row-key="target_id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        stripe border size="mini" height="calc(100vh - 170px)">
        <!-- <el-table-column fixed="left" prop="custom_name" label="对接主体" width="80">
                                        </el-table-column> -->
        <el-table-column fixed="left" prop="username" label="机构/负责人" width="120">
        </el-table-column>


        <el-table-column prop="now_money" label="月度营收达成" width="120">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_money) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="同比" width="80">
          <template slot-scope="scope">
            {{ scope.row.tb_month }}%
          </template>
        </el-table-column>
        <!-- <el-table-column prop="now_money" label="毛利润" width="120">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_money - scope.row.now_yongjin) * 100) / 100 }}
          </template>

        </el-table-column> -->
        <el-table-column prop="month_baifang" label="月度客户拜访" width="100">
        </el-table-column>
        <el-table-column prop="year_baifang" label="年度拜访" width="80">


        </el-table-column>
        <el-table-column prop="now_year_money" label="年度营收达成" width="120">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_year_money) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="同比" width="80">
          <template slot-scope="scope">
            {{ scope.row.tb_year }}%
          </template>
        </el-table-column>
        <!-- <el-table-column prop="now_money" label="毛利润" width="120">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_year_money - scope.row.now_year_yongjin) * 100) / 100 }}

          </template>
        </el-table-column> -->
        <el-table-column prop="now_money" label="目标达成率" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.ys_money">
              {{ parseInt((((scope.row.now_year_money ? scope.row.now_year_money : 0)) /
                (scope.row.ys_money ? scope.row.ys_money : 1)) * 10000) / 100 }}%

            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="年度目标" width="80">
          <template slot-scope="scope">
            {{ scope.row.ys_money }}
          </template>
        </el-table-column>





      </el-table>
    </el-row>

    <el-row v-show="tabIdx == 1">

      <div style="text-align: center; padding: 10px; color:#fff; background-color: #1283FF;">
        <div>{{ ckdMonth }}利润报表</div>
        <div style="font-size:10px">向左滑动屏幕查看详细</div>
      </div>

      <el-table :data="LirunList" row-key="target_id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        stripe border size="mini" height="calc(100vh - 170px)">
        <!-- <el-table-column fixed="left" prop="custom_name" label="对接主体" width="80">
                                        </el-table-column> -->
        <el-table-column fixed="left" prop="username" label="业务员" width="120">
        </el-table-column>

        <el-table-column prop="now_money" label="月度" width="60">
          <template slot-scope="scope">
            {{ ckdMonth.split('-')[1] }}月
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="毛利润" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_money - scope.row.now_yongjin) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="净利润" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_money - scope.row.now_yongjin - scope.row.month_fee1 - scope.row.month_other- scope.row.month_renli) *
              100)
              / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="费用" width="100">
          <template slot-scope="scope">
            {{ (scope.row.month_fee1) }}
          </template>
        </el-table-column>
        <el-table-column prop="month_renli" label="人力成本" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.month_renli) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="年度" width="100">
          <template slot-scope="scope">
            {{ ckdMonth.split('-')[0] }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="毛利润" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_year_money - scope.row.now_year_yongjin) * 100) / 100 }}
          </template>

        </el-table-column>
        <el-table-column prop="now_money" label="净利润" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.now_year_money - scope.row.now_year_yongjin - scope.row.year_fee1 -
              scope.row.year_other
            ) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="now_money" label="费用" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.year_fee1) * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="year_renli" label="人力成本" width="100">
          <template slot-scope="scope">
            {{ parseInt((scope.row.year_renli) * 100) / 100 }}
          </template>
        </el-table-column>

      </el-table>

    </el-row>

</div>
</template>

<script>









let fixedDept = require("./cbDept.js")
export default {
  data() {
    return {
      tabIdx: 0,
      ckdMonth: "",
      DataList: [],
      deptList: [],
      LirunList: []
    }
  },
  mounted() {
    document.title = "营收报表"
    let year = new Date().getFullYear()
    let month = new Date().getMonth()+1
   
    if (month < 10) {
      month = "0" + month
    }
    this.ckdMonth = year + "-" + month
    this.reloadData()
  },
  methods: {
    setCkd(t) {
      this.tabIdx = t
    },
    reloadData() {
      this.getData()
      this.getLirun()

    },
    getData() {

      this.$http.post("/api/ywy_yeji_tongji", {
        year: parseInt(this.ckdMonth.split("-")[0]),
        month: parseInt(this.ckdMonth.split("-")[1])
      }).then(res => {

        let heji = {};

        heji.last1_money = 0;
        heji.last1_yongjin = 0;
        heji.last_year_money = 0;
        heji.last_year_yongjin = 0;
        heji.month_baifang = 0;
        heji.now_money = 0;
        heji.now_year_money = 0;
        heji.now_year_yongjin = 0;
        heji.now_yongjin = 0;
        heji.year_baifang = 0;

        heji.ys_money = 0
        let rData = []
        //计算环比
        for (let item of res.data) {

          if (item.ys_money || item.now_year_money || item.year_baifang) {

            item.tb_month = 0;
            item.tb_year = 0;

            if (item.last1_money) {
              item.tb_month = Math.round(item.last1_money / (item.now_money - item.last1_money) * 10000) / 100
            } else {
              item.tb_month = 100
            }

            if (item.last_year_money) {
              item.tb_year = Math.round(item.last_year_money / (item.now_year_money - item.last_year_money) * 10000) / 100
            } else {
              item.tb_year = 100
            }

            for (let k in heji) {
              if (item[k]) {
                heji[k] += item[k]
              }
            }

            rData.push(item)


          }
        }
        heji.username = "中博集团"
        heji.tb_month = 0;
        heji.tb_year = 0;
        if (heji.last1_money) {
          heji.tb_month = Math.round(heji.last1_money / (heji.now_money - heji.last1_money) * 10000) / 100
        } else {
          heji.tb_month = 100
        }

        if (heji.last_year_money) {
          heji.tb_year = Math.round(heji.last_year_money / (heji.now_year_money - heji.last_year_money) * 10000) / 100
        } else {
          heji.tb_year = 100
        }

        let deptList = JSON.parse(JSON.stringify(fixedDept.deptList))
        for (let dept of deptList) {

          dept.username = dept.name
          dept.ywyList = dept.ywyList.split(",")
          dept.children = []
          //计算部门数据

          dept.last1_money = 0;
          dept.last1_yongjin = 0;
          dept.last_year_money = 0;
          dept.last_year_yongjin = 0;
          dept.month_baifang = 0;
          dept.now_money = 0;
          dept.now_year_money = 0;
          dept.now_year_yongjin = 0;
          dept.now_yongjin = 0;
          dept.year_baifang = 0;
          dept.tb_month = 0;
          dept.tb_year = 0;
          dept.ys_money = 0;
          // dept.hasChildren = true
          for (let u of dept.ywyList) {
            for (let d of rData) {
              if (d.username == u) {
                d.target_id = "d" + d.id;

                dept.children.push(d)
                dept.now_money += d.now_money;
                dept.last1_money += d.last1_money;
                dept.last1_yongjin += d.last1_yongjin;
                dept.last_year_money += d.last_year_money;
                dept.month_baifang += d.month_baifang;
                dept.now_year_money += d.now_year_money;
                dept.now_year_yongjin += d.now_year_yongjin;
                dept.year_baifang += d.year_baifang;
                dept.ys_money += d.ys_money;

                break
              }
            }

            if (dept.last1_money) {
              dept.tb_month = Math.round(dept.last1_money / (dept.now_money - dept.last1_money) * 10000) / 100
            } else {
              dept.tb_month = 100
            }

            if (dept.last_year_money) {
              dept.tb_year = Math.round(dept.last_year_money / (dept.now_year_money - dept.last_year_money) * 10000) / 100
            } else {
              dept.tb_year = 100
            }



          }
        }
        deptList = [heji].concat(deptList)
        this.DataList = deptList

      })
    },
    getLirun() {

      this.$http.post("/api/ywy_lirun_tongji", {
        year: parseInt(this.ckdMonth.split("-")[0]),
        month: parseInt(this.ckdMonth.split("-")[1])
      }).then(res => {
        let uids = []
        let deptList = JSON.parse(JSON.stringify(fixedDept.deptList))
        for (let dept of deptList) {

          dept.username = dept.name
          dept.ywyList = dept.ywyList.split(",")
          dept.children = []
          //计算部门数据

          dept.now_money = 0;
          dept.now_year_money = 0;
          dept.now_year_yongjin = 0;
          dept.now_yongjin = 0;
          dept.month_fee1 = 0;
          dept.month_other = 0;
          dept.month_renli = 0;
          dept.year_fee1 = 0;
          dept.year_other = 0;
          dept.year_renli = 0;
          dept.ys_money = 0;
          // dept.hasChildren = true
          for (let u of dept.ywyList) {
            for (let d of res.data.data) {
              if (d.username == u) {
                d.target_id = "d" + d.id;
                uids.push(d.id)
                dept.children.push(d)
                dept.now_money += d.now_money;
                dept.now_yongjin += d.now_yongjin;
                dept.now_year_money += d.now_year_money;
                dept.now_year_yongjin += d.now_year_yongjin;
                // dept.month_fee1+=d.month_fee1;
                // dept.month_other+=d.month_other;
                // dept.month_renli+=d.month_renli;
                // dept.year_fee1 += d.year_fee1;
                // dept.year_other += d.year_other;
                // dept.year_renli += d.year_renli;
                break
              }
            }
          }

          for (let d of res.data.jigou.jigouMonth) {

            if (d.target_id == dept.target_id) {
              
              dept.month_fee1 =d.fee1;
             
              dept.month_other = d.fee3;
              dept.month_renli = d.fee2;

              break
            }
          }

          for (let d of res.data.jigou.jigouYear) {
            if (d.target_id == dept.id) {

              dept.year_fee1 = d.fee1;
              dept.year_other = d.fee3;
              dept.year_renli = d.fee2;

              break
            }
          }
        }



        // let rData = [];

        // //计算环比
        // for (let item of res.data) {

        //   if (item.now_year_money||item.year_fee1||item.year_other||item.year_renli) {
        //     rData.push(item)
        //   }
        // }

        let heji = {
          username: "中博集团",
          now_money: 0,
          now_year_money: 0,
          now_year_yongjin: 0,
          now_yongjin: 0,
          month_fee1: 0,
          month_other: 0,
          month_renli: 0,
          year_fee1: 0,
          year_other: 0,
          year_renli: 0,
          ys_money: 0,
        }
        for (let dept of deptList) {
          heji.now_money += dept.now_money
          heji.now_year_money += dept.now_year_money
          heji.now_year_yongjin += dept.now_year_yongjin
          heji.now_yongjin += dept.now_yongjin
          heji.month_fee1 += dept.month_fee1
          heji.month_other += dept.month_other
          heji.month_renli += dept.month_renli
          heji.year_fee1 += dept.year_fee1
          heji.year_other += dept.year_other
          heji.year_renli += dept.year_renli
          heji.ys_money += dept.ys_money

          for (let child of dept.children) {
            // heji.month_fee1 += child.month_fee1
            // heji.month_other += child.month_other
            // heji.month_renli += child.month_renli
            // heji.year_fee1 += child.year_fee1
            // heji.year_other += child.year_other
            // heji.year_renli += child.year_renli
          }

        }
        deptList = [heji].concat(deptList)

        this.LirunList = deptList
        console.log(deptList)

        uids = (uids.join(","))
        console.log(uids)

      })
    }

  }
}
</script>

<style scoped>
.tabbox {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  height: 32px;
  border: 1px solid #1283FF;
}

.tabitem {
  display: inline-block;
  line-height: 20px;
  width: 50%;
  color: #888;
  padding: 6px 0;
}

.tabckd {
  background-color: #1283FF;
  color: #fff;
}
</style>
